import './App.css';
import ConstructionAnimation from './ConstructionAnimation'

function App() {
  return (
    <ConstructionAnimation />
  );
}

export default App;
