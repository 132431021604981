import './ConstructionAnimation.css';


function ConstructionAnimation() {
    return (
        <div className="oleo">

            <div className="board mobile">
                <h1><b>WEBSITE UNDER CONSTRUCTION</b></h1>
            </div>

            <div className="moon"></div>


            <div className="truck">
                <div className="cab">
                    <div className="windshield"></div>
                </div>
                <div className="bed"></div>
                <div className="wheel one">
                    <div className="verticalBeam"></div>
                    <div className="horizontalBeam"></div>
                </div>
                <div className="wheel two">
                    <div className="verticalBeam"></div>
                    <div className="horizontalBeam"></div>
                </div>
                <div className="wheel three">
                    <div className="verticalBeam"></div>
                    <div className="horizontalBeam"></div>
                </div>
                <div className="mixer"></div>
            </div>



            <div className="building">
                <div className="storey">
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                </div>
                <div className="storey">
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                </div>
                <div className="storey hideOnMedium">
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                    <div className="wall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                </div>

            </div>


            <div className="crane">
                <div className="trolley">
                    <div className="hoist">

                    </div>
                    <div className="wall hoistWall">
                        <div className="window one"></div>
                        <div className="window two"></div>
                    </div>
                </div>

                <div className="jib">
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="counterWeight"></div>
                </div>

                <div className="mast">
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox hideOnMedium"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                    <div className="mastBox"></div>
                </div>

                <div className="foundation">
                    <div className="upperFoundation"></div>
                    <div className="lowerFoundation"></div>
                </div>
            </div>


            <div className="post desktop"></div>
            <div className="board desktop">
                <h1><b>WEBSITE UNDER CONSTRUCTION</b></h1>
            </div>


        </div>
    );
}

export default ConstructionAnimation;
